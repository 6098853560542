import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Divider, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "divider"
    }}>{`Divider`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Divider } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=413%3A1169'} storybook={'/?path=/story/layout-divider--playground'} vue={'/components/HseDivider/HseDivider.html'} vueStorybook={'?path=/story/layout-divider--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Divider – это компонент для разделения любых элементов на странице. Представляет собой тонкую линию.
Используйте разделитель в том случае, когда недостаточно просто разделить элементы пустым пространством между ними.`}</p>
    <p>{`Имеет два цвета: `}<inlineCode parentName="p">{`light`}</inlineCode>{` и `}<inlineCode parentName="p">{`dark`}</inlineCode>{`. Цвет задается пропом `}<inlineCode parentName="p">{`color`}</inlineCode>{`. `}<inlineCode parentName="p">{`light`}</inlineCode>{` следует использовать на светлом фоне, `}<inlineCode parentName="p">{`dark`}</inlineCode>{` – на темном.`}</p>
    <p>{`У компонента два варианта расположения: вертикальный (`}<inlineCode parentName="p">{`vertical`}</inlineCode>{`) и горизонтальный (`}<inlineCode parentName="p">{`horizontal`}</inlineCode>{`). Передайте в проп `}<inlineCode parentName="p">{`variant`}</inlineCode>{` нужный.`}</p>
    <Playground __position={1} __code={'<Divider />'} __scope={{
      props,
      DefaultLayout,
      Divider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Divider mdxType="Divider" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "цвет-divider"
    }}>{`Цвет Divider`}</h3>
    <p>{`Divider имеет два цвета: `}<inlineCode parentName="p">{`light`}</inlineCode>{` и `}<inlineCode parentName="p">{`dark`}</inlineCode>{`. Передайте в свойство `}<inlineCode parentName="p">{`color`}</inlineCode>{` нужный:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`light`}</inlineCode>{` рекомендуется использовать на белом фоне`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`dark`}</inlineCode>{` рекомендуется использовать на темном фоне`}</p>
      </li>
    </ul>
    <Playground __position={2} __code={'<div>\n  <Divider color={Divider.Color.light} />\n  <Space size={Size.small_2x} vertical />\n  <Divider color={Divider.Color.dark} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Divider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <Divider color={Divider.Color.light} mdxType="Divider" />
    <Space size={Size.small_2x} vertical mdxType="Space" />
    <Divider color={Divider.Color.dark} mdxType="Divider" />
  </div>
    </Playground>
    <h3 {...{
      "id": "варианты-расположения"
    }}>{`Варианты расположения`}</h3>
    <p>{`У компонента два варианта расположения: вертикальный (`}<inlineCode parentName="p">{`vertical`}</inlineCode>{`) и горизонтальный (`}<inlineCode parentName="p">{`horizontal`}</inlineCode>{`). Передайте в проп `}<inlineCode parentName="p">{`variant`}</inlineCode>{` нужный`}</p>
    <Playground __position={3} __code={'<div>\n  <Divider variant={Divider.Variant.horizontal} />\n  <Space size={Size.small_2x} vertical />\n  <Divider style={{ height: \'100px\' }} variant={Divider.Variant.vertical} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Divider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <Divider variant={Divider.Variant.horizontal} mdxType="Divider" />
    <Space size={Size.small_2x} vertical mdxType="Space" />
    <Divider style={{
          height: '100px'
        }} variant={Divider.Variant.vertical} mdxType="Divider" />
  </div>
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Divider} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`hr`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      